
    import { mapGetters } from 'vuex'
    import { Component, Vue } from 'vue-property-decorator'

    // import DashHighscorelist from './highScoreList/index.vue'
    import DashSwiper from './swiper/index.vue'
    import DashTeacher from './teacher/index.vue'
    import DashTeachExplanation from './teacherExplanation/index.vue'
    import DashWorkShop from './workshops/index.vue'
    import DashInfomation from './infomation/index.vue'
    @Component({
        name: 'Dashboard',
        computed: {
            ...mapGetters('common', ['lang'])
        },
        components: { DashSwiper, DashTeacher,DashTeachExplanation,DashWorkShop,DashInfomation }
    })
    export default class Dashboard extends Vue {
      lang!: any
      mounted() {
        console.log(this.lang, '语言')
      }
    }
