
  import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import { Caxios } from "./../../../utils/axios";

@Component({
  name: "DashSwiper",
})
export default class DashSwiper extends Vue {
  private picLists: Array<any> = [];
  private allWidth = 0;
  private singleWidth = 0;
  // private singleHeight: number = document.documentElement.clientWidth * 360 / 1200;
  private singleHeight: number = document.documentElement.clientWidth * 360 / 1920;
  private leftPos = 0;
  private timer: any = null;
  private interval = 3000;
  private animated = false;
  private moveIndex = 1;
  private dotsList: Array<number> = []
  @Prop() lang!: string

  private async initPics() {
    const _res: any = await Caxios.post({ url: '/api/official/home/banner/list' }),
      _len: number = _res.length;
    if(_res.code === '10000'){
      console.log('res.data',_res)
      this.dotsList = new Array(_len).fill(0)
      this.singleWidth = document.documentElement.clientWidth;
      this.picLists = [_res.data[_len - 1]].concat([..._res.data, _res.data[0]]);
      this.allWidth = this.singleWidth * this.picLists.length;
      const img_url = _res.data[0],
          img = new Image(); // 创建对象
      img.src = img_url; // 改变图片的src
      // this.singleHeight = Math.floor((this.singleWidth * img.height) / img.width);
      this.singleHeight = 667
      this.leftPos = -this.singleWidth;

      this.play();
    }
  }

  // 监听语言切换
  @Watch('lang', { immediate: true, deep: true })
  langWatch(newVal: number): void {
    // TODO
    this.initPics()
  }

  private play(): void {
    if (this.picLists.length > 3) {
      this.timer = setTimeout(() => {
        this.swClickEvent();
        this.play();
      }, this.interval);
    }
  }

  private swClickEvent(index?: number): void {
    this.stop();
    if (this.animated) {
      return;
    }
    if(index) {
      this.moveIndex = index
    }
    if (this.moveIndex == this.picLists.length - 2) {
      this.moveIndex = 1;
    } else {
      this.moveIndex += 1;
    }
    this.animate(-this.singleWidth);
  }

  private animate(offset: number): void {
    if (offset == 0) {
      return;
    }
    this.animated = true;
    const _this: any = this,
      _time = 200,
      _interval = 10,
      speed: number = offset / (_time / _interval),
      _left: number = parseInt(_this.leftPos) + offset,
      go = function () {
        if (
          (speed < 0 && parseInt(_this.leftPos) > _left) ||
          (speed > 0 && parseInt(_this.leftPos) < _left)
        ) {
          _this.leftPos = parseInt(_this.leftPos) + speed;
          setTimeout(go, _interval);
        } else {
          _this.animated = false;
          _this.leftPos = _left;
          if (_left > -_this.singleWidth) {
            _this.leftPos = -_this.singleWidth * (_this.picLists.length - 2);
          }
          if (_left < -_this.singleWidth * (_this.picLists.length - 2)) {
            _this.leftPos = -_this.singleWidth;
          }
        }
      };
    go();
  }

  private stop(): void {
    clearTimeout(this.timer);
    this.timer = null;
  }
  private dotChangeEvent (index: number): void {
    this.swClickEvent(index)
  }

  mounted() {
   /* // TODO
    this.initPics();*/
    const win: any = window;
    // win.onresize = () => {
    //   this.initPics();
    // };
  }
}
