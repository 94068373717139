
import { Component, Vue, Prop } from "vue-property-decorator";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
const teacher1 = "https://fangtian-education.oss-cn-beijing.aliyuncs.com/app/icon/miniapp/teacher1.png",
  teacher2 = "https://fangtian-education.oss-cn-beijing.aliyuncs.com/app/icon/miniapp/teacher2.png",
  teacher3 = "https://fangtian-education.oss-cn-beijing.aliyuncs.com/app/icon/miniapp/teacher3.png",
  teacher4 = "https://fangtian-education.oss-cn-beijing.aliyuncs.com/app/icon/miniapp/teacher4.png",
  teacher5 = "https://fangtian-education.oss-cn-beijing.aliyuncs.com/app/icon/miniapp/teacher5.png",
  teacher6 = "https://fangtian-education.oss-cn-beijing.aliyuncs.com/app/icon/miniapp/teacher6.png",
  teacher7 = "https://fangtian-education.oss-cn-beijing.aliyuncs.com/app/icon/miniapp/teacher7.png",
  teacher8 = "https://fangtian-education.oss-cn-beijing.aliyuncs.com/app/icon/miniapp/teacher8.png",
  teacher9 = "https://fangtian-education.oss-cn-beijing.aliyuncs.com/app/icon/miniapp/teacher9.png",
  teacher10 = "https://fangtian-education.oss-cn-beijing.aliyuncs.com/app/icon/miniapp/teacher10.png",
  teacher11 = "https://fangtian-education.oss-cn-beijing.aliyuncs.com/app/icon/miniapp/teacher11.png",
  teacher12 = "https://fangtian-education.oss-cn-beijing.aliyuncs.com/app/icon/miniapp/teacher12.png";
@Component({
  name: "TeacherSetions",
})
export default class TeacherSetions extends Vue {
  @Prop() lang!: string;

  private onSwiper(swiper: any): void {
    console.log(swiper);
  }
  private onSlideChange(): void {
    console.log("swiperchange");
  }
  private teacherInfo = [
    {
      name: "ZhangTao",
      gep: "GEP（P2-P3）",
      photo: teacher1,
      info: "Teacher Zhang is currently the head of P2-P3 and GEP Mathematics. In 2023, 75% of his P3 students progressed to the second round of the GEP selection, and 25% successfully entered the GEP class.",
    },
    {
      name: "Cui Jia ming",
      gep: "Primary Olympiad Mathematics（P4-P6）",
      photo: teacher2,
      info: "Teacher Cui is the lead teacher for upper primary levels. From 2023-2024, he has led students to win over 20 NMOS gold medals, 8 APMOPS platinum awards, and nearly half of his students can advance to the second round of the RMO. ",
    },
    {
      name: "Smith",
      gep: "Primary Olympiad Mathematics（P5-P6）",
      photo: teacher3,
      info: "Teacher Smith has 3+ years of experience in teaching Mathematics. He is the lead teacher of the P5 & P6 PSLE and is a teacher of P6 Math Olympiad. He is committed to assisting students in excelling in NMOS, RMO and APMOPS.",
    },
    {
      name: "Meng Tiantian",
      gep: "PSLE (P5-P6)",
      photo: teacher6,
      info: "Teacher Meng has been teaching for more than 7 years and is currently the vice principal of Kangaroo Study. In Spring Cup (with difficulty higher than RMO), 61 students of her received first, second, and third prizes. She currently leads the development of the PSLE team, focusing on enhancing student performance to achieve AL1 in PSLE.",
    },
    {
      name: "Ma Zhangchi",
      gep: "PSLE (P5-P6)",
      photo: teacher7,
      info: "Coming from a family of math teachers, teacher Ma gained teaching experience as a teaching assistant during college. He currently teaches PSLE Math, prioritizing clarity and encouraging active engagement.",
    },
    {
      name: "Donnie",
      gep: "PSLE(P4)",
      photo: teacher11,
      info: "With 10 years of teaching experience, Teacher Donnie has nurtured students of various characters to achieve academic excellence. With honor, he is the Lead Teaching Specialist and Co-developer of Kangaroo Study P4 Mathematics Curriculum.",
    },
    {
      name: "Jay",
      gep: "PSLE(P4)",
      photo: teacher12,
      info: "Graduated from National University of Singapore, teacher Jay is currently the Mathematics Teacher in P4 and is the Leader of Research and Teaching for the P4-P6 curriculum. He likes to explain concepts with daily examples and he inspires students to find the beauty of mathematics in day-to-day life.",
    },
    {
      name: "Gao Xuming",
      gep: "Secondary (S1-S2)",
      photo: teacher9,
      info: "Teacher Gao is well-versed in the secondary Olympiad mathematics system. In 2024, 13 of his students advanced to the second round of the SMO Junior Group, 6 won Gold Medals, and 3 advanced to the Junior Training Team.",
    },
    {
      name: "Jeremiah Ang",
      gep: "Primary Olympiad Mathematics（S1-S2）",
      photo: teacher5,
      info: "Teacher Ang is a talented and experienced mathematics education expert, currently serving as the Head of Primary to Secondary School Transition and Teaching Supervisor at Kangaroo.Study.",
    },
    {
      name: "Zhang Wenlong",
      gep: "Secondary (S1-S2)",
      photo: teacher10,
      info: "Teacher Zhang gained extensive teaching experience as a teaching assistant throughout the university years. He advocates for guided teaching in secondary school math to foster independent thinking and problem-solving skills.",
    },
  ];
  private teachSwiper: any = null;
  mounted() {
    const teachSwiper = new Swiper(".mySwiper", {
      slidesPerView: "auto",
      loop: !0,
      lazy: {
        loadPrevNext: !0,
      },
      preloadImages: !1,
      loopAdditionalSlides: 9,
      loopedSlides: 5,
      autoplay: {
        delay: 0, //自动切换的时间间隔
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      speed: 6e3,
      spaceBetween: 30,
      freeMode: !0,

      interval: 1500,
      duration: 1500,
      navigation: {
        nextEl: null,
        prevEl: null,
      },
    });
    this.teachSwiper = teachSwiper;

    // const swiperContainer = document.querySelector(".mySwiper");
    // const _this: any = this;
    // (swiperContainer as Element).addEventListener("mouseenter", function () {
    //   console.log(teachSwiper)
    //   // 鼠标移入时停止自动播放
    //   _this.$nextTick(() => {
    //     teachSwiper.autoplay.stop();
    //   });
    // });

    // (swiperContainer as Element).addEventListener("mouseleave", function () {
    //   // 鼠标移出时开始自动播放
    //   _this.$nextTick(() => {
    //     teachSwiper.autoplay.start();
    //   });
    //   // teachSwiper.autoplay.start();
    // });
  }
  private enterSwiper(): void {
    this.$nextTick(() => {
      this.teachSwiper.autoplay.stop();
    });
  }
  private leaveSwiper(): void {
    this.$nextTick(() => {
      this.teachSwiper.autoplay.start();
    });
  }
  beforeDestroy() {
    this.teachSwiper = null;
  }
}
