
import { Component, Vue, Prop } from "vue-property-decorator";
const infomationImg1 = 'https://fangtian-education.oss-cn-beijing.aliyuncs.com/app/icon/miniapp/infomation1.png',
  infomationImg2 = 'https://fangtian-education.oss-cn-beijing.aliyuncs.com/app/icon/miniapp/infomation2.png',
  infomationImg3 = 'https://fangtian-education.oss-cn-beijing.aliyuncs.com/app/icon/miniapp/infomation3.png',
  infomationImg4 = 'https://fangtian-education.oss-cn-beijing.aliyuncs.com/app/icon/miniapp/infomation4.png';
@Component({
  name: "Infomation",
})
export default class Infomation extends Vue {
  @Prop() lang!: string;

  private infomationList = [
    {
      img: infomationImg1,
      title: "Latest Events",
      content: "",
      url: "",
    },
    {
      img: infomationImg2,
      title: "News & Updates",
      content:
        "“Jumpstart for New Sec 1 Stude-nts” Guidance Seminar Successf ...",
      url: "/infomation/index",
    },
    {
      img: infomationImg3,
      title: "Analysis & Guidance",
      content: "",
      url: "",
    },
    {
      img: infomationImg4,
      title: "Latest Events",
      content: "",
      url: "",
    },
  ];
  private goDetail(item: any) {
    item.url && this.$router.push(item.url);
  }
}
